import { Injectable } from '@angular/core';
import JSZip from 'jszip';
import {
	ConvertToEpsModel,
	EditS3FileNameRequest,
	S3DeleteFileRequest,
	S3DeleteFileVersionRequest,
	S3DeleteResponse,
	S3File,
	S3FileDownloadResponse,
	S3FileInfoRequest,
	S3FileUploadRequest,
	S3FileUploadResponse,
	S3FileVersionUpdateRequest,
	S3GetFileDownloadRequest,
	S3PresignedFileUploadRequest,
	S3PresignedUrlResponse,
	S3VideoResponse,
	S3VideoThumbnailUploadRequest,
} from '../api-data/ng-openapi-gen-next/models';
import { ApiService } from '../api-data/ng-openapi-gen-next/services/api.service';
import { Observable, filter, lastValueFrom, map } from 'rxjs';
import { StrictHttpResponse } from '../api-data/ng-openapi-gen-next/strict-http-response';
import { RequestBuilder } from '../api-data/ng-openapi-gen-next/request-builder';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { PresignedFileDownloadModel, PresignedUrlFileUploadModel, Result, S3UploadResponseStatus, SuccessResponse } from '../models/models';
import saveAs from 'file-saver';
import { ResultHelper } from '../common/result-extension';
import { ErrorHandlingService } from './error-handling.service';

export const ErrorRetrievingFile: string = 'Error retrieving file';
export const ErrorUploadingFile: string = 'Error uploading file';

@Injectable({
	providedIn: 'root',
})
export class S3FileService {
	/**
	 * Record used to map file extensions such as `pdf`
	 * to their MIME type such as `application/pdf`
	 *
	 * Included file extensions: `pdf` `doc` `rtf` `docx` `xlsx` `xls` `jpg` `jpeg` `bmp` `tiff` `weba` `webm`
	 *
	 * Usage: `FileType['pdf']` evaluates to `'application/pdf'`
	 */
	FileType: Record<string, string> = {
		pdf: 'application/pdf',
		doc: 'application/msword',
		rtf: 'application/msword',
		docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		xls: 'application/vnd.ms-excel',
		jpg: 'image/jpg',
		jpeg: 'image/jpg',
		bmp: 'image/bmp',
		tiff: 'image/tiff',
		mp4: 'video/mp4',
		mov: 'video/mov',
		weba: 'video/webm',
		webm: 'video/webm',
		jfif: 'image/jpg',
		png: 'image/png',
		eps: 'application/eps',
	};

	constructor(
		private apiV2: ApiService,
		private http: HttpClient,
		private errorHandlingService: ErrorHandlingService
	) {}

	getMimeType(fileType: string): string {
		try {
			const mimeType: string = this.FileType[fileType];
			return mimeType;
		} catch (e) {
			throw new Error(`Don't recognize that type`);
		}
	}

	async uploadS3FilePresignedURL(file: File, fileGUID?: string, versionGUID?: string): Promise<S3FileUploadResponse> {
		try {
			const arrFileNameParts = file.name.split('.');
			const fileType: string = arrFileNameParts[arrFileNameParts.length - 1];
			const s3UploadRequest: S3FileUploadRequest = {
				fileBody: '',
				fileName: file.name,
				contentType: this.FileType[fileType],
				fileGUID: fileGUID,
				versionGUID: versionGUID,
			};

			const s3UploadPresignedUrlResponse = await lastValueFrom(this.apiV2.fetchS3UploadPresignedUrl({ body: s3UploadRequest }));

			if (s3UploadPresignedUrlResponse.isSuccess && s3UploadPresignedUrlResponse.data?.preSignedUrl) {
				const fileUploadResponse: boolean = await lastValueFrom(this.http.put(s3UploadPresignedUrlResponse.data.preSignedUrl, file))
					.then(() => {
						console.log('success Uploading file');
						return true;
					})
					.catch((error: any) => {
						console.error('Error Uploading file', error);
						return false;
					});
				if (fileUploadResponse) {
					return {
						s3FileGUID: s3UploadPresignedUrlResponse.data.s3FileGUID,
						s3FileVersionGUID: s3UploadPresignedUrlResponse.data.versionGUID,
					};
				} else {
					console.log('failed to upload the file');
					throw new Error('Received presigned url but failed to upload the file');
				}
			} else {
				console.log(s3UploadPresignedUrlResponse);
				throw new Error('Failed to upload file.');
			}
		} catch (e) {
			console.error('Error: ', e);
			throw new Error('Could not upload file.');
		}
	}

	async downloadS3FilePreSignedUrl(request: S3GetFileDownloadRequest): Promise<PresignedFileDownloadModel | undefined> {
		try {
			const uploadPresignedUrlResponse = await lastValueFrom(this.apiV2.fetchS3ViewPresignedUrl({ body: request }));
			if (uploadPresignedUrlResponse.isSuccess && uploadPresignedUrlResponse.data) {
				let downloadResponse: PresignedFileDownloadModel = {
					fileName: uploadPresignedUrlResponse.data.fileName ?? '',
					presignedUrl: uploadPresignedUrlResponse.data.preSignedUrl ?? '',
					fileType: uploadPresignedUrlResponse.data.fileType ?? '',
					file: undefined,
				};
				this.errorHandlingService.showSuccessMessage(`Downloading ${downloadResponse.fileName}`);
				const fetchResponse = await fetch(uploadPresignedUrlResponse.data.preSignedUrl ?? '');
				const blob = await fetchResponse.blob();
				const file: File = new File([blob], `${uploadPresignedUrlResponse.data.fileName!}.${uploadPresignedUrlResponse.data.fileType!}`);
				this.errorHandlingService.showSuccessMessage(`Finished Download`);
				// lcross: hopefully no need to await this. The client is indifferent about the response and there is a failsafe job just in case (see CleanUpOutBoundBucket job)
				await lastValueFrom(
					this.apiV2.removeFromOutboundBucket({
						body: { tempFileLabel: `${uploadPresignedUrlResponse.data.fileVersionGUID}/${uploadPresignedUrlResponse.data.fileName}.${uploadPresignedUrlResponse.data.fileType}` },
					})
				);
				downloadResponse.file = file;
				return downloadResponse;
			} else {
				console.log('Problem getting presigned url: ', uploadPresignedUrlResponse);
				return;
			}
		} catch (err) {
			console.error('Error: ', err);
			throw 'error';
		}
	}
	async fetchS3FilePreSignedUrl(request: S3GetFileDownloadRequest): Promise<PresignedFileDownloadModel | undefined> {
		try {
			const uploadPresignedUrlResponse = await lastValueFrom(this.apiV2.fetchS3ViewPresignedUrl({ body: request }));
			if (uploadPresignedUrlResponse.isSuccess && uploadPresignedUrlResponse.data) {
				let downloadResponse: PresignedFileDownloadModel = {
					fileName: uploadPresignedUrlResponse.data.fileName ?? '',
					presignedUrl: uploadPresignedUrlResponse.data.preSignedUrl ?? '',
					fileType: uploadPresignedUrlResponse.data.fileType ?? '',
					file: undefined,
				};
				return downloadResponse;
			} else {
				console.log('Problem getting presigned url: ', uploadPresignedUrlResponse);
				return;
			}
		} catch (err) {
			console.error('Error: ', err);
			throw 'error';
		}
	}
	/**
	 * @deprecated `YOU PROBABLY SHOULDN'T BE USING THIS ANYMORE`
	 */
	async uploadFile(name: string, file: File, fileGUID?: string): Promise<string> {
		return new Promise((resolve, reject) => {
			try {
				let fileType: string = name.split('.')[-1];
				const zipper: JSZip = new JSZip();
				zipper.file(name, file);
				zipper.generateAsync({ type: 'blob', compression: 'DEFLATE', compressionOptions: { level: 9 } }).then(async (blob) => {
					const reader = new FileReader();
					reader.readAsDataURL(blob);
					reader.onload = async () => {
						const s3UploadRequest: S3FileUploadRequest = {
							fileBody: reader.result as string,
							fileName: name,
							contentType: this.FileType[fileType],
							fileGUID: fileGUID,
						};
						const s3UploadPromise = await lastValueFrom(this.apiV2.uploadS3File({ body: s3UploadRequest }));
						if (s3UploadPromise.isSuccess && s3UploadPromise.data?.s3FileGUID) {
							resolve(s3UploadPromise.data.s3FileGUID);
						} else {
							console.log(ErrorUploadingFile);
							reject(Error(ErrorUploadingFile));
						}
					};
				});
			} catch (e) {
				reject(e);
			}
		});
	}

	/**
	 * @deprecated `YOU PROBABLY SHOULDN'T BE USING THIS ANYMORE`
	 */
	async uploadProfileImage(name: string, file: File, fileGUID?: string, skipVersionUpdate: boolean = false, versionGUID?: string): Promise<Result<S3FileUploadResponse>> {
		return new Promise((resolve, reject) => {
			try {
				let fileType: string = name.split('.')[-1];
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = async () => {
					const s3UploadRequest: S3FileUploadRequest = {
						fileBody: reader.result as string,
						fileName: name,
						contentType: this.FileType[fileType],
						fileGUID: fileGUID,
						skipVersionUpdate: skipVersionUpdate,
						versionGUID: versionGUID,
					};
					const s3UploadPromise = await lastValueFrom(this.apiV2.uploadS3FileWithoutEncryption({ body: s3UploadRequest }));
					if (s3UploadPromise.isSuccess && s3UploadPromise.data?.s3FileGUID) {
						resolve(ResultHelper.successResponse<S3FileUploadResponse>(s3UploadPromise.data));
					} else {
						console.log(ErrorUploadingFile);
						reject(Error(ErrorUploadingFile));
					}
				};
			} catch (e) {
				reject(e);
			}
		});
	}

	/**
	 * @deprecated `YOU PROBABLY SHOULDN'T BE USING THIS ANYMORE`
	 */
	async uploadFileNoCompression(name: string, file: File, fileGUID?: string): Promise<string> {
		return new Promise((resolve, reject) => {
			try {
				let fileType: string = name.split('.')[-1];
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = async () => {
					const s3UploadRequest: S3FileUploadRequest = {
						fileBody: reader.result as string,
						fileName: name,
						contentType: this.FileType[fileType],
						fileGUID: fileGUID,
					};
					const s3UploadPromise = await lastValueFrom(this.apiV2.uploadS3File({ body: s3UploadRequest }));
					if (s3UploadPromise.isSuccess && s3UploadPromise.data?.s3FileGUID) {
						resolve(s3UploadPromise.data.s3FileGUID);
					} else {
						console.log(ErrorUploadingFile);
						reject(Error(ErrorUploadingFile));
					}
				};
			} catch (e) {
				reject(e);
			}
		});
	}

	/**
	 * @deprecated `YOU PROBABLY SHOULDN'T BE USING THIS ANYMORE`
	 */
	async downloadS3File(fileID: string): Promise<File> {
		return new Promise(async (resolve, reject) => {
			try {
				const s3DownloadRequest: S3GetFileDownloadRequest = {
					s3FileGUID: fileID,
				};
				const s3DownloadResponse = await lastValueFrom(this.apiV2.getS3FileDownload({ body: s3DownloadRequest }));
				if (s3DownloadResponse.isSuccess) {
					const fileName: string = s3DownloadResponse.data?.fileName!;
					const fileData: string = s3DownloadResponse.data?.fileData!;
					const fileType: string = s3DownloadResponse.data?.fileType!;
					const byteChars = atob(fileData);
					const byteNums = new Array(byteChars.length);
					for (let i = 0; i < byteChars.length; i++) byteNums[i] = byteChars.charCodeAt(i);
					const byteArray = new Uint8Array(byteNums);
					let zippedBlob: Blob = new Blob([byteArray]);
					const unzipper: JSZip = new JSZip();
					const unzippedFile = await unzipper.loadAsync(zippedBlob);
					Promise.resolve(unzippedFile)
						.then((unzipped) => {
							return unzipped.files[Object.keys(unzipped.files)[0]];
						})
						.then((unzippedFile) =>
							unzipper
								.file(unzippedFile.name)
								?.async('blob')
								.then(async (unzippedBlob) => {
									unzippedBlob = unzippedBlob.slice(0, unzippedBlob.size, this.FileType[fileType]);
									let file: File = new File([unzippedBlob], fileName + '.' + fileType, { type: this.FileType[fileType] });
									resolve(file);
								})
						);
				} else {
					console.log('No file found to download');
					throw 'No file found to download';
				}
			} catch (e) {
				reject(e);
			}
		});
	}

	/**
	 * @deprecated `YOU PROBABLY SHOULDN'T BE USING THIS ANYMORE`
	 */
	async downloadS3FileNoCompression(fileID: string): Promise<File> {
		return new Promise(async (resolve, reject) => {
			try {
				const s3DownloadRequest: S3GetFileDownloadRequest = {
					s3FileGUID: fileID,
				};
				const s3DownloadResponse = await lastValueFrom(this.apiV2.getS3FileDownload({ body: s3DownloadRequest }));
				if (s3DownloadResponse.isSuccess) {
					const fileName: string = s3DownloadResponse.data?.fileName!;
					const fileData: string = s3DownloadResponse.data?.fileData!;
					const fileType: string = s3DownloadResponse.data?.fileType!;
					const byteChars = atob(fileData);
					const byteNums = new Array(byteChars.length);
					for (let i = 0; i < byteChars.length; i++) byteNums[i] = byteChars.charCodeAt(i);
					const byteArray = new Uint8Array(byteNums);
					let file: File = new File([byteArray], fileName + '.' + fileType, { type: this.FileType[fileType] });
					resolve(file);
				} else {
					console.log('No file found to download');
					throw 'No file found to download';
				}
			} catch (e) {
				reject(e);
			}
		});
	}

	/**
	 * @deprecated `YOU PROBABLY SHOULDN'T BE USING THIS ANYMORE`
	 */
	async downloadUserIdentityDocuments(request: S3GetFileDownloadRequest): Promise<Result<S3FileDownloadResponse>> {
		return await lastValueFrom(this.apiV2.getUserIdentityDocuments({ body: request }))
			.then(async (response) => {
				if (response.isSuccess && response.data) {
					return ResultHelper.successResponse<S3FileDownloadResponse>(response.data);
				}
				throw 'Failed to fetch data';
			})
			.catch((error) => {
				return ResultHelper.failedResponse<S3FileDownloadResponse>(error);
			});
	}

	/**
	 * @deprecated `YOU PROBABLY SHOULDN'T BE USING THIS ANYMORE`
	 */
	async downloadStaticS3File(fileID: string): Promise<File> {
		return new Promise(async (resolve, reject) => {
			try {
				const s3DownloadRequest: S3GetFileDownloadRequest = {
					s3FileGUID: fileID,
				};
				const s3DownloadResponse = await lastValueFrom(this.getClientS3FileDownload({ body: s3DownloadRequest }));

				const test = s3DownloadResponse;
				const fileName: string = 'Overdraft Protection.zip';
				const fileData: string = test;
				const fileType: string = 'pdf';
				const byteChars = atob(fileData);
				const byteNums = new Array(byteChars.length);
				for (let i = 0; i < byteChars.length; i++) byteNums[i] = byteChars.charCodeAt(i);
				const byteArray = new Uint8Array(byteNums);
				let zippedBlob: Blob = new Blob([byteArray]);
				const unzipper: JSZip = new JSZip();
				const unzippedFile = await unzipper.loadAsync(zippedBlob);
				Promise.resolve(unzippedFile)
					.then((unzipped) => {
						return unzipped.files[Object.keys(unzipped.files)[0]];
					})
					.then((unzippedFile) =>
						unzipper
							.file(unzippedFile.name)
							?.async('blob')
							.then(async (unzippedBlob) => {
								unzippedBlob = unzippedBlob.slice(0, unzippedBlob.size, this.FileType[fileType]);
								let file: File = new File([unzippedBlob], fileName + '.' + fileType, { type: this.FileType[fileType] });
								resolve(file);
							})
					);
			} catch (e) {
				reject(e);
			}
		});
	}

	/**
	 * Delete target file
	 *
	 * @param fileGUID target file GUID
	 *
	 * @returns `Promise<boolean>` true or false based on success of deletion
	 */
	async deleteFile(fileID: string): Promise<boolean> {
		return new Promise(async (resolve, reject) => {
			try {
				const deleteRequest: S3DeleteFileRequest = {
					s3FileGUID: fileID,
				};
				const deleteDocumentPromise = await lastValueFrom(this.apiV2.deleteS3File({ body: deleteRequest }));
				if (deleteDocumentPromise.isSuccess && deleteDocumentPromise.data) {
					const deleteDocumentResult: S3DeleteResponse = deleteDocumentPromise.data;
					if (deleteDocumentResult) resolve(true);
				}
				resolve(false);
			} catch (e) {
				console.log('Error: ', e);
				reject(e);
			}
		});
	}

	/**
	 * Gets file info for target file: FileGUID, BucketGUID, FileName, FileType
	 *
	 * @param fileId GUID of target file
	 *
	 * @returns `Promise<S3File>` Promise containing S3File object with file info
	 */
	async getFileInfo(fileId: string): Promise<S3File> {
		return new Promise(async (resolve, reject) => {
			try {
				const fileInfoRequest: S3FileInfoRequest = {
					fileGUID: fileId,
				};
				const fileInfoPromise = await lastValueFrom(this.apiV2.getFileInfo({ body: fileInfoRequest }));
				if (fileInfoPromise.isSuccess && fileInfoPromise.data) {
					resolve(fileInfoPromise.data);
				} else throw Error('File not found');
			} catch (e) {
				reject(e);
			}
		});
	}

	/**
	 * Edit file name
	 *
	 * @param fileId GUID of target file
	 * @param newName String containing new file name
	 *
	 * @returns `Promise<boolean>` Promise containing `true` if the update was successful
	 */
	async editFileName(fileId: string, newName: string): Promise<boolean> {
		return new Promise(async (resolve, reject) => {
			try {
				const editS3FileNameRequest: EditS3FileNameRequest = {
					s3FileGUID: fileId,
					label: newName,
				};
				const editFileNamePromise = await lastValueFrom(this.apiV2.editS3FileName({ body: editS3FileNameRequest }));
				if (editFileNamePromise.isSuccess) {
					resolve(true);
				} else resolve(false);
			} catch (e) {
				reject(e);
			}
		});
	}

	/**
	 * @deprecated `YOU PROBABLY SHOULDN'T BE USING THIS ANYMORE`
	 */
	async uploadFileWithUrl(url: string, name: string, fileType: string): Promise<string> {
		try {
			const request: S3FileUploadRequest = {
				fileURL: url,
				fileName: name,
				contentType: this.FileType[fileType],
			};
			const response = await lastValueFrom(this.apiV2.uploadS3FileFromLink({ body: request }));
			if (response.isSuccess) {
				return response.data?.s3FileGUID!;
			} else throw new Error('Error saving file');
		} catch (e) {
			console.log('Error: ', e);
			throw e;
		}
	}

	/**
	 * @deprecated `YOU PROBABLY SHOULDN'T BE USING THIS ANYMORE`
	 */
	private getClientS3FileDownload(params: { body: S3GetFileDownloadRequest }): Observable<any> {
		return this.getClientS3FileDownload$Response(params).pipe(map((r: StrictHttpResponse<any>) => r.body as any));
	}

	/**
	 * @deprecated `YOU PROBABLY SHOULDN'T BE USING THIS ANYMORE`
	 */
	getClientS3FileDownload$Response(params: { body: S3GetFileDownloadRequest }): Observable<StrictHttpResponse<void>> {
		console.log('The root url is--,', this.apiV2.rootUrl);
		const rb = new RequestBuilder(this.apiV2.rootUrl, ApiService.GetClientS3FileDownloadPath, 'post');
		if (params) {
			rb.body(params.body, 'application/json');
		}

		return this.http
			.request(
				rb.build({
					responseType: 'text',
					accept: '*/*',
				})
			)
			.pipe(
				filter((r: any) => r instanceof HttpResponse),
				map((r: HttpResponse<any>) => {
					return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
				})
			);
	}

	/**
	 * @deprecated `YOU PROBABLY SHOULDN'T BE USING THIS ANYMORE`
	 */
	async getAllS3Videos(): Promise<S3VideoResponse[]> {
		return new Promise(async (resolve, reject) => {
			try {
				const filesPromise = await lastValueFrom(this.apiV2.getAllS3Videos());
				if (filesPromise.isSuccess && filesPromise.data) {
					if (filesPromise.data.items) resolve(filesPromise.data.items);
				} else throw Error('File not found');
			} catch (e) {
				reject(e);
			}
		});
	}

	/**
	 * @deprecated `YOU PROBABLY SHOULDN'T BE USING THIS ANYMORE`
	 */
	async uploadVideoFileToS3(file: File, fileGUID?: string): Promise<string> {
		return new Promise((resolve, reject) => {
			try {
				let arrFileNameParts = file.name.split('.');
				let fileType: string = arrFileNameParts[arrFileNameParts.length - 1];
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = async () => {
					const s3UploadRequest: S3FileUploadRequest = {
						fileBody: reader.result as string,
						fileName: file.name,
						contentType: this.FileType[fileType],
						fileGUID: fileGUID,
					};
					const s3UploadPromise = await lastValueFrom(this.apiV2.uploadVideoToS3({ body: s3UploadRequest }));
					if (s3UploadPromise.isSuccess && s3UploadPromise.data?.s3FileGUID) {
						resolve(s3UploadPromise.data.s3FileGUID);
					} else {
						console.log(ErrorUploadingFile);
						reject(Error(ErrorUploadingFile));
					}
				};
			} catch (e) {
				reject(e);
			}
		});
	}

	/**
	 * @deprecated `YOU PROBABLY SHOULDN'T BE USING THIS ANYMORE`
	 */
	async putS3FileWithPreSignedUrl(url: string, data: File): Promise<S3UploadResponseStatus> {
		try {
			// const content = new Blob([data]).size;

			// Note: Removing explicit set of content length header property
			return await lastValueFrom(this.http.put(url, data))
				.then(() => {
					console.log('success Uploading file');
					return S3UploadResponseStatus.SUCCESS;
				})
				.catch((error: any) => {
					console.log('Error Uploading file', error);
					return S3UploadResponseStatus.RETRY;
				});
		} catch (err) {
			console.error('Error: ', err);
			return S3UploadResponseStatus.FAILURE;
		}
	}

	/**
	 * @deprecated `YOU PROBABLY SHOULDN'T BE USING THIS ANYMORE`
	 */
	async uploadWikiVideoWithPreSignedUrl(file: File, fileGUID?: string, videoThumbnailGUID?: string): Promise<S3PresignedUrlResponse | null> {
		try {
			const arrFileNameParts = file.name.split('.');
			const fileType: string = arrFileNameParts[arrFileNameParts.length - 1];

			const reader = new FileReader();
			reader.readAsDataURL(file);
			const s3UploadRequest: S3FileUploadRequest = {
				fileBody: '',
				fileName: file.name,
				contentType: this.FileType[fileType],
				fileGUID: fileGUID,
				s3VideoThumbnailGUID: videoThumbnailGUID,
			};

			const s3UploadPromise = await lastValueFrom(this.apiV2.fetchS3UploadPresignedUrl({ body: s3UploadRequest }));

			if (s3UploadPromise.isSuccess && s3UploadPromise.data?.preSignedUrl) {
				const s3Response = s3UploadPromise.data;
				let attempt = 0;
				const maxRetryDuration = 60 * 1000; // Maximum retry duration in milliseconds (4 minutes)
				const startTime = Date.now(); // Start time of the upload process

				let response: S3UploadResponseStatus;
				while (Date.now() - startTime < maxRetryDuration) {
					response = await this.putS3FileWithPreSignedUrl(s3UploadPromise.data?.preSignedUrl, file);
					console.log('response from upload', response);
					if (response == S3UploadResponseStatus.SUCCESS) {
						let request: S3FileUploadRequest = {
							fileGUID: s3Response.s3FileGUID,
							versionGUID: s3Response.versionGUID,
							fileName: file.name,
						};
						const uploadVideoResponse = await lastValueFrom(this.apiV2.uploadS3Video({ body: request }));

						if (uploadVideoResponse && uploadVideoResponse.data) {
							return uploadVideoResponse.data ?? '';
						} else {
							throw 'Failed to upload video';
						}
					} else if (response == S3UploadResponseStatus.RETRY) {
						attempt++;
						console.warn(`Upload attempt ${attempt} failed. Retrying... ${Date.now() - startTime}`);
						await new Promise((resolve) => {
							setTimeout(() => {
								resolve(true);
							}, 15000);
						});
					} else {
						throw new Error('Failed to upload file.');
					}
				}
			}
			throw new Error('Failed to upload file.');
		} catch (e) {
			console.error('Error: ', e);
			throw new Error('Could not upload file.');
		}
	}

	/**
	 * @deprecated `YOU PROBABLY SHOULDN'T BE USING THIS ANYMORE`
	 */
	async uploadVideoThumbnail(file: File, s3VideoFileGUID?: string, s3VideoGUID?: string): Promise<string> {
		return new Promise((resolve, reject) => {
			try {
				let arrFileNameParts = file.name.split('.');
				let fileType: string = arrFileNameParts[arrFileNameParts.length - 1];
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = async () => {
					const s3UploadRequest: S3VideoThumbnailUploadRequest = {
						fileBody: reader.result as string,
						fileName: file.name,
						contentType: this.FileType[fileType],
						videoFileGUID: s3VideoFileGUID,
						s3VideoGUID: s3VideoGUID,
					};
					const s3UploadPromise = await lastValueFrom(this.apiV2.uploadVideoThumbnail({ body: s3UploadRequest }));
					if (s3UploadPromise.isSuccess && s3UploadPromise.data?.s3FileGUID && s3UploadPromise.data?.s3VideoThumbnailGUID) {
						resolve(s3UploadPromise.data.s3VideoThumbnailGUID);
					} else {
						reject(Error(ErrorUploadingFile));
					}
				};
			} catch (e) {
				reject(e);
			}
		});
	}

	async convertToEps(request: ConvertToEpsModel): Promise<File> {
		return new Promise(async (resolve, reject) => {
			try {
				const s3DownloadResponse = await lastValueFrom(
					this.apiV2.convertToEps({
						body: request,
					})
				);
				if (s3DownloadResponse.isSuccess) {
					const fileData: string = s3DownloadResponse.data?.base64Data!;
					const byteChars = atob(fileData);
					const byteNums = new Array(byteChars.length);
					for (let i = 0; i < byteChars.length; i++) byteNums[i] = byteChars.charCodeAt(i);

					const byteArray = new Uint8Array(byteNums);
					let file: File = new File([byteArray], 'new_qr_code' + '.' + 'eps', { type: this.FileType['eps'] });
					resolve(file);
				} else {
					console.log('No file found to download');
					throw 'No file found to download';
				}
			} catch (e) {
				reject(e);
			}
		});
	}

	/**
	 * @deprecated `YOU PROBABLY SHOULDN'T BE USING THIS ANYMORE`
	 */
	async downloadS3FileWithNoEncryptAndZip(fileID: string, s3FileVersionGUID?: string): Promise<File> {
		return new Promise(async (resolve, reject) => {
			try {
				const s3DownloadRequest: S3GetFileDownloadRequest = {
					s3FileGUID: fileID,
					s3FileVersionGUID: s3FileVersionGUID,
				};
				const s3DownloadResponse = await lastValueFrom(this.apiV2.getS3FileDownloadWithoutDecryption({ body: s3DownloadRequest }));
				if (s3DownloadResponse.isSuccess) {
					const fileName: string = s3DownloadResponse.data?.fileName!;
					const fileData: string = s3DownloadResponse.data?.fileData!;
					const fileType: string = s3DownloadResponse.data?.fileType!;
					const byteChars = atob(fileData);
					const byteNums = new Array(byteChars.length);
					for (let i = 0; i < byteChars.length; i++) byteNums[i] = byteChars.charCodeAt(i);
					const byteArray = new Uint8Array(byteNums);
					let file: File = new File([byteArray], fileName + '.' + fileType, { type: this.FileType[fileType] });
					resolve(file);
				} else {
					console.log('No file found to download');
					throw 'No file found to download';
				}
			} catch (e) {
				reject(e);
			}
		});
	}

	/**
	 * @deprecated `YOU PROBABLY SHOULDN'T BE USING THIS ANYMORE`
	 */
	async uploadS3FileWithPresignedURL(data: PresignedUrlFileUploadModel): Promise<S3FileUploadResponse> {
		try {
			const arrFileNameParts = data.file.name.split('.');
			const fileType: string = arrFileNameParts[arrFileNameParts.length - 1];
			const s3UploadRequest: S3FileUploadRequest = {
				fileBody: '',
				fileName: data.file.name,
				contentType: this.FileType[fileType],
				fileGUID: data.fileGUID,
				versionGUID: data.fileVersionGUID,
			};

			const s3UploadPromise = await lastValueFrom(this.apiV2.fetchS3UploadPresignedUrl({ body: s3UploadRequest }));

			if (s3UploadPromise.isSuccess && s3UploadPromise.data?.preSignedUrl) {
				const s3Response = s3UploadPromise.data;
				let response: S3UploadResponseStatus = await this.putS3FileWithPreSignedUrl(s3UploadPromise.data?.preSignedUrl, data.file);
				if (response == S3UploadResponseStatus.SUCCESS) {
					if (!data.skipRecordUpdate) {
						let request: S3PresignedFileUploadRequest = {
							fileGUID: s3Response.s3FileGUID,
							versionGUID: s3Response.versionGUID,
							fileName: data.file.name,
							createNewFile: data.createNewFile,
							skipS3VersionUpdate: data.skipVersionUpdate,
						};

						const saveS3FileResponse = await lastValueFrom(this.apiV2.saveS3File({ body: request }));

						if (saveS3FileResponse && saveS3FileResponse.data) {
							return saveS3FileResponse.data;
						} else {
							throw 'Failed to upload video';
						}
					} else {
						return {
							s3FileGUID: s3Response.s3FileGUID,
							s3FileVersionGUID: s3Response.versionGUID,
						};
					}
				} else {
					throw new Error('Failed to upload file.');
				}
			}
			throw new Error('Failed to upload file.');
		} catch (e) {
			console.error('Error: ', e);
			throw new Error('Could not upload file.');
		}
	}

	/**
	 * @deprecated `YOU PROBABLY SHOULDN'T BE USING THIS ANYMORE`
	 */
	async downloadS3FileWithPreSignedUrl(request: S3GetFileDownloadRequest): Promise<PresignedFileDownloadModel> {
		try {
			const uploadResponse = await lastValueFrom(this.apiV2.fetchS3ViewPresignedUrl({ body: request }));
			if (uploadResponse.isSuccess) {
				return {
					fileName: uploadResponse.data?.fileName ?? '',
					presignedUrl: uploadResponse.data?.preSignedUrl ?? '',
					fileType: uploadResponse.data?.fileType ?? '',
					file: undefined,
				};
			} else {
				throw 'error';
			}
		} catch (err) {
			console.error('Error: ', err);
			throw 'error';
		}
	}
	/**
	 * Delete target file
	 *
	 * @param s3FileGUID target file GUID
	 * @param s3FileVersionGUID target file version GUID
	 *
	 * @returns `Promise<boolean>` true or false based on success of deletion
	 */
	async deleteFileVersion(s3FileGUID: string, s3FileVersionGUID: string): Promise<boolean> {
		return new Promise(async (resolve, reject) => {
			try {
				const deleteRequest: S3DeleteFileVersionRequest = {
					s3FileGUID,
					s3FileVersionGUID,
				};
				const deleteDocumentPromise = await lastValueFrom(this.apiV2.deleteS3FileVersion({ body: deleteRequest }));
				if (deleteDocumentPromise.isSuccess && deleteDocumentPromise.data) {
					const deleteDocumentResult: S3DeleteResponse = deleteDocumentPromise.data;
					if (deleteDocumentResult) resolve(true);
				}
				resolve(false);
			} catch (e) {
				console.log('Error: ', e);
				reject(e);
			}
		});
	}

	/**
	 * update file version of target file
	 *
	 * @param s3FileGUID target file GUID
	 * @param s3FileVersionGUID target file version GUID
	 *
	 * @returns `Promise<boolean>` true or false based on success of update
	 */
	async updateS3FileWithVersionGuid(data: S3FileVersionUpdateRequest): Promise<boolean> {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await lastValueFrom(this.apiV2.updateS3FileWithVersionGuid({ body: data }));
				if (response.isSuccess) {
					resolve(true);
				}
				resolve(false);
			} catch (e) {
				console.log('Error: ', e);
				reject(e);
			}
		});
	}
}
